import React from 'react';
import { Navbar, Header, Footer } from '../src/sections/index';

function App() {
  return (
    <div>
      <Navbar/>
      <Header/>
      <Footer/>   
    </div>
  );
}

export default App;
