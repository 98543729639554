import Aos from 'aos';
import "./header.css"
import video from '../asset/bg-video.mov';
import { useEffect } from 'react';

const Header = () => {

  useEffect(()=>{
    Aos.init({duration:2000});
  },[])

  return (
    <header>
      <div className='bg-video'  data-aos="zoom-in" data-aos-duration="3000">
        <video src={video}autoPlay="autoplay" loop="loop" muted  />
       
        <div className='header__title'>
            <h1>Revolutionizing<br/>Liquidity Markets</h1>
             <p>Building the best borrowing experience in DeFi</p>
              <div className='button__div'>
                <button className="header__btnOne"><a href="https://app.tender.fi/" target ="_blank" rel="noopener noreferrer">BORROW</a></button>
                <button className='header__btnTwo'><a href="https://app.tender.fi/" target ="_blank" rel="noopener noreferrer">LEND</a></button>
             </div> 
        </div>
      </div>


    </header>
  )
}

export default Header