import {useEffect} from 'react'
import { Link } from "react-router-dom";
import AOS from "aos";
import './navbar.css'
import  logo from '../asset/Sohei1.png'
import { Divide as Hamburger } from 'hamburger-react'
import { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";


const NavMenu = () => {


  return (
    <div className="nav__menu-container ">
      <div className="nav__menu-container-link scale-up-ver-top">
        <ul className="nav__menu-link">
          <li><a href="#doc">Doc</a></li>
          <li><a href="#comm">Community</a></li>
        </ul>
        <button className="nav__menu-btnn">
          <a href="https://app.tender.fi/" target="_blank" rel="noopener noreferrer">LAUNCH APP</a>
        </button>
      </div>
      <div className="nav__menu-container-link-sign" />
    </div>
  );
};



const Navbar = () => {

  const[toggle, setToggle]= useState(false)

  useEffect(()=>{
    AOS.refresh();
    AOS.init({duration:2000});
  },[])

 

  return (
    <nav>
        <div className='container nav__container'>
            <a href="index.html" className='nav__logo'>
                <img src={logo} alt="logo"  style={{width: "100px"}}/>
            </a>
            <ul className = "nav__menu" data-aos='zoom-in' >
                <li><a href="#doc">Doc</a></li>
                <li><a href="#comm">Community</a></li>            
            </ul>
            <button className='btnn'> <a href="https://app.tender.fi/" target ="_blank" rel="noopener noreferrer">LAUNCH APP</a> </button>
               
               
            <div className='navbar-menu  ' >
              <div className="hamburgar" data-aos=' zoom-out'>
                    {toggle
                  ?
                    <RiCloseLine color="#fff" size={35} onClick={()=>setToggle(false)}  data-aos="flip-left"/>
                  :<RiMenu3Line color="#fff" size={30} onClick={()=>setToggle(true)}  data-aos="flip-right"/>
                
                  }
              </div>

              {toggle&& <NavMenu />} 
            </div> 
          
       

             
            
        </div>

    </nav>
  )
}

export default Navbar